<template>
  <div class="block">
    <el-carousel height="100vh">
      <el-carousel-item v-for="(item,index) in swiperArr" :key='index'>
        <img v-lazy="baseUrl+item.bannerUrl" alt="" class="img" @click="goDetail(item)">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data(){
    return{
      swiperArr:[],
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms"
    }
  },
  mounted() {
    this.home();
  },
  methods: {
    async home() {
      let resoult = await this.$http({
        url: "/api/adApi?page=1&size=5&cxfl=8&classify=80",
        method: "get",
      });
      // console.log(resoult);
      this.swiperArr = resoult.data.data
    },
    goDetail(item){
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:item.detailUrl
        },
      });
    }
  },
};
</script>

<style>
/* 轮播 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

/* .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */
.img{
  width: 100%;
  height: 1080px;
  cursor: pointer;
}
</style>