<template>
  <div class="culture-box">
      <div class="public-box-img"
      :style="{backgroundImage: 'url(' + coverImgUrl + ')', backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat'}"
      ></div>
      <Djmtwo></Djmtwo>
  </div>
</template>

<script>
import Djmtwo from "../djmjtwo/djmjtwo";
export default {
  components: {
    Djmtwo
  },
  data() {
    return {
      coverImgUrl: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/wenhua.jpg'
    }
  },
  mounted() {
    console.log(this.$i18n.locale);
    if(this.$i18n.locale == 'en') {
      this.coverImgUrl = 'http://qiniu.image.citgroup.cn/heibeizhangjiakou/pc/wenhua.jpg';
    }
  }
};
</script>

<style>
.culture-box{
    overflow: hidden;
}
.culture-box .public-box-img{
    width: 100%;
    height: 600px;
    /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/wenhua.jpg) no-repeat; */
    margin: 0px auto ;
}
</style>