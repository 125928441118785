<template>
  <div class="publicdetails">
    <div class="box">
      <div class="t1">{{ datailArr.title }}</div>
      <!-- <div class="adress">{{datailArr.address }}</div> -->
      <!-- <div class="phone">{{datailArr.phone }}</div> -->
      <!-- <div class="price">{{datailArr.price }}</div> -->
      <div class="briefInfo">{{datailArr.briefInfo }}</div>
      <img :src="baseUrl + datailArr.imgUrl" alt="" class="img" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      datailArr: {},
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.home();
  
  },
  methods: {
    async home() {
      let result = await this.$http({
        url: "/api/strategyApi/getDetail/" + this.id,
        // url: `/api/travelStoreApi/getDetail/${this.id}`,
        method: "get",
      });
      this.datailArr = result.data.data;
      console.log(result);
    },
  },
};
</script>

<style>
.publicdetails {
  width: 1200px;
  /* height: 100vh; */
  background: #cecece;
  margin: 0 auto;
  overflow: hidden;
}
.publicdetails .box {
  width: 100%;
  height: 500px;
  background: green;
  position: relative;
}
.publicdetails .box .img {
  width: 738px;
  height: 448px;
  background: yellow;
  margin: 10px auto;
}
.publicdetails .box .t1 {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
  /* text-align: left; */
  margin-top: 88px;
  margin-left: 12px;
}
.publicdetails .box .adress {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 28px;
  position: absolute;
  left: 800px;
  top: 50px;
}
.publicdetails .box .phone {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 28px;
  position: absolute;
  left: 800px;
  top: 100px;
}
.publicdetails .box .price {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 28px;
  position: absolute;
  left: 800px;
  top: 150px;
}
.publicdetails .box .briefInfo {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 28px;
  position: absolute;
  left: 800px;
  top: 200px;
}
</style>