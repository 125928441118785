<template>
  <div class="publicdetailsdangji">
   <div class="boxdangji" v-html="datailArr.content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      datailArr: {},
      baseUrl: "http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms",
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.home();
  },
  methods: {
    async home() {
      let result = await this.$http({
        url: "/api/activityApi/getDetail/" + this.id,
        // url: `/api/travelStoreApi/getDetail/${this.id}`,
        method: "get",
      });
      this.datailArr = result.data.data;
      console.log(result);
    },
  },
};
</script>

<style scoped>
.publicdetailsdangji {
  width: 1200px;
  /* height: 100vh; */
  /* background: #cecece; */
  margin: 0 auto;
  overflow: hidden;
}
.publicdetailsdangji .boxdangji {
  width: 100%;
  height: auto;
  margin-top: 88px;
}
</style>